import * as React from "react";
import * as styles from './pagecomponentlayout.module.css';
import BannerRSRLogo from "../utils/banner-rsr-logo";

const PageComponentLayout = ({children, title}) => {
  return (
    <>
    <BannerRSRLogo />
    <section className={styles.pageComponentBanner}>
        <div className={styles.pageComponentContainer}>
            <div className={styles.pageComponentTitleContainer}>
                <h1 className={styles.pageComponentTitle}>{title}</h1>
                <div className={styles.pageComponentTitleDivider}></div>
            </div>
            <div className={styles.childrenContainer}>
                {children}
            </div>
        </div>
    </section>
    </>
  );
};

export default PageComponentLayout;
