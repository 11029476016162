import * as React from "react";
import Layout from "../components/layout";
import PageComponentLayout from "../components/pagecomponents/pagecomponentlayout";
import Reglamento from "../components/pagecomponents/reglamento";
import Seo from "../components/utils/seo";

const ReglamentoPage = () => {
  return (
    <Layout>
      <PageComponentLayout title="Reglamento">
        <Reglamento />
      </PageComponentLayout>
    </Layout>
  );
};

export default ReglamentoPage;

export const Head = () => <Seo title="Reglamento" />
