import * as React from 'react';
import * as styles from './banner-rsr-logo.module.css';
import * as basestyles from './../layout.module.css';
import { StaticImage } from 'gatsby-plugin-image';

const BannerRSRLogo = () => {
    return (
        <div className={styles.bannerRSRLogo}>
            <div className={[basestyles.bannerContainer, styles.logoBannerContainer].join(' ')}>
                <div className={styles.bannerLogoWrapper}>
                    <StaticImage 
                        alt="Logo de Run Santa Run"
                        src="./../../images/banners/principal/logo-RSR.svg"
                    />
                </div>
            </div>
        </div>
    );
};

export default BannerRSRLogo;