import * as React from "react";
import * as styles from './reglamento.module.css';

const Reglamento = () => {
  return (
    <div className={styles.reglamentoContainer}>
      <ul>
        <li>Todos deberán portar el uniforme TOTALMENTE completo, persona que no lo porte será descalificado.</li>
        <li>El número deberá de ir en la parte delantera del saco.</li>
        <li>Al salir y llegar a la meta, deberán de traer bien acomodado todos los accesorios, el saco bien cerrado, el gorro y la barba puesta.</li>
      </ul>

      <p>SERVICIOS MÉDICOS: Contaremos con servicio médico en la SALIDA y META.</p>
    </div>
  );
};

export default Reglamento;






